
.vue-processing-fees-templates {
   input[type="text"] {
        width: 90%;
    }

    button.add-row {
        margin-left: 10px;
        font-size: small;

        svg {
            padding-top: 4px;
        }
    }
}
