
.table-sticky thead {
    position          : sticky;
    inset-block-start : 0;
    z-index           : 100;
}

.table-sticky thead th.white {
    background-color : #fff;
}

.table-sticky tfoot {
    position        : sticky;
    inset-block-end : 0;
    z-index         : 99;
}

.table-sticky tfoot td.white {
    background-color : #fff;
}

tfoot td {
    font-weight    : bold;
    text-transform : uppercase;
}

.pointer {
    cursor : pointer;
}
