@import "base";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import "~@fortawesome/fontawesome-free/scss/core";
@import "~@fortawesome/fontawesome-free/scss/shims";

.navbar .navbar-nav > li {
  &.dropdown.account-menu .dropdown-menu {
    .divider {
      margin: 0;
    }
  }

  &.dropdown.language-menu {
    .dropdown-menu {
      margin-top: -5px;

      &:before, &:after, {
        border-bottom: 11px solid $color-white;
        border-right: 11px solid transparent;
        border-left: 11px solid transparent;
        content: "";
        display: inline-block;
        position: absolute;
        top: -10px;
        right: 12px;
        left: auto;
      }

      &:before {
        border-bottom: 11px solid rgba(0, 0, 0, .2);
        top: -11px;
      }
    }

    .dropdown-toggle:after {
      content: "";
      display: block;
      background-size: cover;
      height: 2.2rem;
      width: 2.2rem;
      margin-top: .15rem;
      border: none;
      background-color: $color-gray;
      mask: url("../img/language.svg") no-repeat center;
      mask-size: cover;
      -webkit-mask: url("../img/language.svg") no-repeat center;
      -webkit-mask-size: cover;
    }
    .dropdown-menu li {
      & > label {
        display: block;
        cursor: pointer;
        color: $color-dark;
        padding: 8px 16px;
        margin: 0;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        white-space: nowrap;

        &:hover {
          background-color: $color-light-gray;
        }
      }

      &:first-child > label {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &:last-child > label {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.checkbox label {
  padding-left: 0;
}

.id {
  font-family: monospace;
  overflow-wrap: break-word;
  font-size: 90%;
}

.nav-tabs {
  .dropdown-menu > li:first-child > a {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

h3 .link-back {
  margin: 3px 7px;
}

a.decorated {
  text-decoration: underline;
}

a.plain {
  color: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &.link-icon {
    text-decoration: none;
    font-size: smaller;
    opacity: 0.9;
  }

  .link-icon {
    text-decoration: none;
    font-size: x-small;
    opacity: 0.5;
  }
}

a.link-button {
  border: none;
  color: $color-link-blue;
  cursor: pointer;
  margin-left: 0;
  overflow: hidden;
  padding-left: 0;
  position: relative;
  text-decoration: underline;
  font-size: 15px;
}

legend + small {
  display: block;
  margin-top: -10px;
  margin-bottom: 20px;
}

.change-password {
  form.form-horizontal {
    label {
      line-height: 40px;
    }
  }
}

@media (min-width:768px) {
  .statistics {
    .dl-horizontal dt {
      width: 280px;
    }

    .dl-horizontal dd {
      margin-left: 300px;
    }
  }
}

label {
  &.required {
    &:after {
      content: "*";
      padding: 0 0 0 4px;
      font-weight: bold;
      color: $color-red;
      text-decoration: none;
      display: inline-block;
    }
  }
}

.is-inline-block {
  display: inline-block;
}

.meta-data {
  width: 100%;
  max-width: 100%;
  overflow: scroll;
}

.pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

[data-notify="message"] {
  a {
    color: inherit;
    text-decoration: underline;
  }
}

.copy {
  font-family: monospace;

  &:after {
    font-family: 'Pe-icon-7-stroke';
    content: "\e665";
    visibility: hidden;
    padding-left: 4px;
    cursor: pointer;
    color: $color-dark;
    font-weight: bold;
  }

  &:hover:after, &.copied:after {
    visibility: visible;
  }

  &.copied:after {
    content: "\e66c";
  }
}

.info-tooltip {
  color: $color-gray;
  cursor: help;
  vertical-align: sub;
  margin: 0 4px;
  font-size: 16px;
}

.open .bs-searchbox .form-control {
  border-bottom-color: rgb(71, 79, 82);;
  border-radius: 4px;
}

.transaction-progress {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 0 10px 0;

  .step {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    transform: skew(-15deg);
    background-color: #ccc;
    color: white;
    padding: 5px 10px;
    margin: 0 1px;
    box-shadow: rgba(0, 0, 0, .25) 1px 1px;

    &.red {
      background-color: $color-red;
    }

    &.yellow {
      background-color: $color-yellow;
    }

    &.green {
      background-color: $color-green;
    }
  }
}

.mime-type {
  @extend .fa;

  &.image-jpeg, &.image-jpg, &.image-png {
    @extend .fa-file-image-o;
  }
  &.application-pdf, &.application-x-pdf {
    @extend .fa-file-pdf-o;
  }

  &.general-file, &.application-x-pdf {
    @extend .fa-regular;
    @extend .fa-file;
  }
}

tr.transaction-status-initialized,
tr.transaction-status-aborted,
tr.transaction-status-disputed,
tr.transaction-status-voided,
tr.transaction-status-cancelled,
tr.transaction-status-refunded.zero-balance {
  td {
    opacity: .4;
    text-decoration: line-through;
  }

  small {
    display: inline-block;
  }
}

.table-transactions, .table-accounting {
  tbody, thead {
    tr {
      td, th {
        padding: 4px 4px;
        vertical-align: middle;

        .checkbox-icon {
          margin: 0 5px;
          vertical-align: baseline;
          cursor: pointer;
        }
      }
    }
  }
}

.transaction-status {
  text-transform: uppercase;

  &.badge {
    font-size: 1em;
    vertical-align: baseline;
  }

  &.initialized, &.authorized {
    background-color: $color-dark;
  }

  &.captured, &.settled {
    background-color: $color-green;
  }

  &.refunded, &.cancelled, &.voided, &.disputed, &.aborted {
    background-color: $color-red;
  }

  &.refunded.has-pending-refunds {
    background-color: $color-orange;
  }
}

h3 .transaction-status {
  margin-left: 3px;
}

.sidebar-menu {
  p {
    margin: 20px 25px;
  }

  ul.nav li {
    a {
      margin: 2px 15px;
      font-weight: bold;

      i, p {
        line-height: 24px;
        font-weight: bold;
      }

      i {
        font-size: 20px;
      }
    }

    &.group {
      & > div {
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.6);
        font-weight: bold;
        transform: rotate(270deg);
        transform-origin: right top;
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 240px;
      }

      & > ul.nav {
        margin-left: 30px;
      }
    }

    &.divider {
      text-align: left;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.4);
      font-weight: bold;
      font-size: 80%;
      margin: 20px 0 8px 20px;
    }

    & > a {
      padding: 0 4px;
    }

    &.section {
      margin: 10px 0 0 64px;
      color: rgba(255, 255, 255, 0.4);

      p {
        line-height: 24px;
      }
    }

    .menu-badge {
      text-transform: none;
      font-size: smaller;
      font-weight: bold;
      color: greenyellow;
      margin-left: 1rem;
    }
  }
}

@media (min-width: 992px) {
  .table-full-width .footer {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.table-full-width h4 {
  margin-left: 15px;
}

table.table table.table {
  th, td {
    font-size: 12px;
    padding: 4px 8px;
  }
}

.transaction-details-page {
  dt {
    white-space: nowrap;
  }

  dt, dd {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .subtransactions {
    margin: 0 0 20px 0;

    dt {
      text-transform: uppercase;
      float: left;
      width: 65%;
      font-weight: normal;
      text-align: left;
    }

    dd {
      margin-left: 65%;
      text-align: right;
    }

    dt, dd {
      font-size: 120%;
      padding: 10px 0;
      border-bottom: 1px solid #ddd;

      i {
        font-weight: bold;
      }
    }

    dt.credit {
      color: $color-green;
    }

    dt.debit {
      color: $color-red;
    }

    dt.balance, dt.balance + dd {
      font-size: 150%;
      font-weight: bold;
    }
  }

  .popover {
    max-width: 640px;
    max-height: 480px;
  }
}

.login-widget {
  margin-top: 150px;

  .pe-7s-unlock {
    display: none;
  }

  &:hover {
    .pe-7s-lock {
      display: none;
    }

    .pe-7s-unlock {
      display: inline;
    }
  }
}

#avatar {
  margin-top: 5px;
  width: 50px;
  height: 50px;
}

// hide selector on number inputs
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.table.table-lg th,
.table.table-lg td {
  font-size: 200%;
}

.table.table-sm th,
.table.table-sm td {
  font-size: 80%;
  line-height: 1.2;
  padding: 6px 4px;
}

.table th.important,
.table td.important {
  font-weight: bold;
}

.table .radio .icons, .table .checkbox .icons {
  left: 0;
}

.card-tabs li a {
  padding: 15px 15px 0;
}

.card-tabs .title {
  display: block;
  color: #333333;
  font-weight: 300;
  margin: 0;
}

.card-tabs .category {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #9A9A9A;
  line-height: 1.5;
  margin-bottom: 4px;
}

.card-tabs li:not(.active) {
  opacity: .8;
}

.card-tabs li:not(.active):hover {
  opacity: 1;
}

#form_accounting {
  .tag-toggles {
    display: flex;
    align-items: center;

    & > button {
      margin-left: 5px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.table-accounting {
  .label {
    text-transform: uppercase;
  }

  input.withholding {
    width: 120px;
    padding: 6px;
    height: 35px;
  }

  > tbody {
    > tr:nth-of-type(4n+3), > tr:nth-of-type(4n+1) {
      td {
        border-bottom: 0;
        padding-bottom: 2px;
      }
    }

    > tr:nth-of-type(4n+4), > tr:nth-of-type(4n+2) {
      td {
        border-top: 0;
        padding-top: 2px;
      }
    }

    > tr:nth-of-type(4n+3), > tr:nth-of-type(4n+4) {
      background-color: #f9f9f9;
    }
  }
}

.popover-control {
  cursor: pointer;
  display: inline-block;
}

.rich-popover-content {
  p, pre {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  pre {
    display: inline-block;
    white-space: pre-line;
  }
}

[data-popover-content] {
  display: none;
}

.balance-details-tables {
  display: flex;
  align-items: start;
  gap: 20px;
  width: 700px;
}

.table-balance-details {
  margin-bottom: 0;

  th, td {
    padding: 5px !important;

    &:nth-child(2) {
      text-align: right;
    }
  }
}

#env_banner {
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -250px;
  width: 500px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  z-index: 10;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  cursor: pointer;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination > li > a, .pagination > li > span {
  color: #1D62F0;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #3472F7;
}

.link-back {
  font-weight: bold;
  vertical-align: top;
}

.dcc-was-used,
.three-ds-was-used,
.green-enlarged-icon, .enlarged-icon {
  color: $color-green;
  font-size: 150%;
  font-weight: bold;

  &.success {
    color: $color-green;
  }

  &.default {
    color: $color-gray;
  }

  &.warning {
    color: $color-orange;
  }

  &.danger {
    color: $color-red;
  }
}

.red-enlarged-icon {
  color: $color-red;
  font-size: 150%;
  font-weight: bold;
}

.table a {
  i[class^="pe-7s-"] {
    font-size: 180%;
    font-weight: bold;
    color: #333;
    vertical-align: bottom;

    &[class="pe-7s-check"] {
      color: $color-green;
    }

    &[class="pe-7s-less"] {
      color: $color-red;
    }
  }

  &.disabled i[class^="pe-7s-"] {
    cursor: default;
    opacity: .5;
  }

  &.disabled i[class^="pe-7s-smile"] {
    cursor: default;
    opacity: 1;
    color: gold;
  }
}

tr.empty {
  height: 75px;

  td {
    text-align: center;
    vertical-align: middle;
  }
}

.tab-pane.card {
  box-shadow: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #ddd;
}

.bootstrap-select .dropdown-menu {
  display: none;
}

.bootstrap-select.open .dropdown-menu {
  display: block;
}

.sort-col[data-order] {
  white-space: nowrap;
  cursor: pointer;
}

.sort-col[data-order] span.asc, .sort-col[data-order] span.desc,
.sort-col[data-order="desc"] span.asc, .sort-col[data-order="desc"] span.sort,
.sort-col[data-order="asc"] span.desc, .sort-col[data-order="asc"] span.sort {
  display: none;
}

.sort-col[data-order="desc"] span.desc, .sort-col[data-order="asc"] span.asc {
  display: inline-block;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  visibility: visible;
  opacity: 1;
}

.refunds-list, .table-accounting {
  .btn-group {
    margin-right: 2px;

    button {
      border-width: 1px;
      font-size: 8pt;
      font-weight: bold;
    }
  }

  span {
    &.label {
      margin-right: 2px;
    }
  }
}

.refunds-list {
  span {
    &.label {
      text-transform: uppercase;
    }
  }
}

.filter-toggle {
  text-decoration: underline;
  .caret {
    content: "";
    margin-left: 6px;
    border-top: 0;
    border-bottom: 4px dashed;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .filter-toggle-area div[class*="col-"] fieldset.filter-group {
    border-left: none;
    padding: 0;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .filter-toggle-area div[class*="col-"] {
    &:nth-child(odd) fieldset.filter-group {
      border-left: none;
      padding-left: 5px;
    }

    &:nth-child(even) fieldset.filter-group {
      padding-right: 5px;
    }
  }
}

@media (min-width: $screen-lg-min) {
  .filter-toggle-area div[class*="col-"]:first-child fieldset.filter-group {
    border-left: none;
  }
}

.filter-toggle-area {
  div[class*="col-"]:first-child {
    padding-left: 5px;
  }

  div[class*="col-"]:last-child {
    padding-right: 5px;
  }

  margin-bottom: 20px;
  padding: 0 15px;

  div[class*="col-"] {
    padding: 0;

    &:first-child fieldset {
      padding-left: 0;
    }

    &:last-child fieldset {
      padding-right: 0;
    }
  }

  fieldset {
    margin-top: 0;
    padding: 0 20px;

    &.filter-group {
      border-left: solid 1px #e7e7e7;
    }

    legend {
      width: auto;
      border: 0;
    }

    .number-range-widget {
      .row > div[class*="col-"]:first-child {
        padding-left: 0;
        padding-right: 5px;
      }
      .row > div[class*="col-"]:last-child {
        padding-right: 0;
        padding-left: 5px;
      }

      & > .row {
        margin: 0;
      }
    }
  }
}

.filter-toggle {
  display: inline-block;
  margin-bottom: 20px;
}

.filter-toggle.collapsed .caret {
  border-top: 4px dashed;
  border-bottom: none;
}

#merchant_export_modal .modal-footer .form-group {
  display: inline-block;
}

@media (min-width: 992px) {
  .card {
    form.edit-form, form.create-form {
      [class*="col-"]:last-child, [class*="col-"]:first-child {
        padding-right: 6px;
        padding-left: 6px;
      }
    }
  }
}

header.wizard {
  position: relative;
  text-align: center;

  .back-link {
    position: absolute;
    right: 15px;
    top: 22px;
  }

  .logo {
    display: inline-block;
    width: 200px;
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.wizard-instructions {
  background-color: lighten($color-green, 50%);
  padding: 12px;
  margin: 0 15px 20px 15px;

  &, p {
    font-size: 12px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.logo {
  padding: 0;

  .image {
    margin: 5px 15px;
    height: 55px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.account-selector {
  &.open .dropdown-menu {
    border-radius: 0;
    border-width: 0;

    & > li > a {
      color: #333333 !important;
    }

    & > li.disabled > a {
      color: #9a9a9a !important;
      cursor: default;
    }
  }

  .btn, &.open > .btn {
    background-color: inherit !important;
    border: none;
    opacity: 1;

    &:hover, &:focus, &:active {
      color: inherit;
      background-color: inherit !important;
    }
  }

  & > button {
    color: #ffffff;
    border: none;

    .caret {
      margin: 0 10px 0 5px;
    }
  }

  & > button, .active-account {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;

    .logo {
      border-bottom: none;

      .image {
        width: 45px;
        max-height: 55px;
        margin: 0;
      }
    }

    .account {
      margin: 0 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      .merchant {
        font-weight: bold;
      }
    }
  }

  .dropdown-menu {
    width: 100%;

    li > a {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

    }
  }
}

.wrapper {
  .logo.sm {
    display: none;
  }
}

.login-widget .form-header {
  margin: 0 0 20px 0;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -moz-box-pack: justify;

  align-items: center;
  justify-content: center;

  img {
    width: 35%;
  }
}

.wrapper.sidebar-sm {
  .main-panel {
    width: calc(100% - 68px);
  }

  .sidebar {
    width: 68px;

    .nav {
      > li {
        &.sidebar-toggle-collapse {
          display: none;
        }

        &.sidebar-toggle-expand {
          display: block;
        }
      }
    }
  }

  .logo, .account-selector {
    display: none;
  }

  .logo.sm {
    display: block;
    padding: 10px 0;

    .image {
      height: 30px;
      margin: 0;
    }
  }

  .sidebar-wrapper {
    width: 68px;

    ul.nav {
      i {
        float: none;
      }

      li {
        &.section {
          margin: 0;
          text-align: center;

          p {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 50px;
          }
        }

        > a {
          > p {
            display: none;
          }
        }
      }
    }
  }
}

body > .navbar-collapse .nav > li {
  text-transform: uppercase;

  & > a {
    padding: 8px 15px;
  }

  &.section {
    margin: 10px 15px 0 15px;

    p {
      color: rgba(255, 255, 255, 0.4);
      font-weight: 600;
    }
  }
}

.sidebar {
  .nav {
    margin-bottom: 56px;

    > li {
      &.sidebar-toggle-collapse, &.sidebar-toggle-expand {
        opacity: .4;
        position: absolute;
        bottom: 0;
        width: 100%;
        margin-bottom: 6px;
      }

      &.sidebar-toggle-collapse {
        display: block;
      }

      &.sidebar-toggle-expand {
        display: none;
      }
    }
  }
}

tr.hidden-row {
  > td {
    padding: 0 !important;
    border-top: none !important;
  }

  table {
    margin-bottom: 0;
  }
}

form {
  fieldset {
    margin-top: 15pt;
  }

  fieldset:first-child {
    margin-top: 0;
  }
}


@media (max-width: $screen-xs-max) {
  .container-fluid {
    &.profile {
      margin: auto -30px;
    }
  }

  .card .content {
    padding: 0;
  }
}

.container-fluid {
  &.profile {
    .nav-tabs {
      border-bottom: 0;

      & > li {
        margin: 0;
      }
    }

    .tos-accepted-by {
      margin: 2em 0;
      text-align: center;

      a {
        &.skip-btn {
          margin: 0 15px;
          text-decoration: underline;
        }
      }
    }

    .card {
      margin-top: 15px;
    }

    .terms-of-service {
      max-height: 30em;
      min-height: 20em;
      overflow-y: auto;
      margin: 1em 0 2em;
      padding: 1em;
      border: solid 1px #E3E3E3;
      border-radius: 3px;
      font-size: 14px;

      h1 {
        font-size: 18px;
      }
      h2 {
        font-size: 16px;
      }
      h3 {
        font-size: 14px;
      }
      p {
        font-size: 14px;
        line-height: 1.2;
      }
    }

    .description {
      font-size: 14px;
      text-align: center;
    }

    .next-btn, .back-btn {
      border-radius: 4px;
    }

    .next-btn {
      margin-left: 10px;
      text-decoration: underline;
    }

    .form-footer {
      margin-bottom: 1em;

      & > div, & > form {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        flex-direction: row;

        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        -moz-box-pack: justify;

        justify-content: space-between;
      }
    }

    .legal-info, .profile-bank-account {
      label {
        color: #333;
        font-size: 13px;
        margin-bottom: 2px;
      }
    }

    .legal-info {
      .choice-help-text {
        padding-left: 0;
      }

      .group-help-text {
        margin: -10px 0 20px;
        display: block;
      }

      .collection-widget-list {
        .field-list-item {
          .delete-item {
            top: 0;
            right: -30px;
          }
        }
      }

      .form-group.form-widget {
        margin-left: -10px;
        margin-right: -10px;
      }

      .address-widget .row {
        margin-left: -6px;
        margin-right: -6px;
      }

      .customer-websites-list {
        .field-list-item {
          padding-top: 0;
          margin: 0 30px 5px 0;

          .delete-item {
            top: 7px;
            right: -25px;
          }
        }

        .group-help-text {
          margin-bottom: 0;
          margin-top: 5px;
        }
      }
    }

    .business-information {
      .alert-top {
        margin: 0;
      }

      .file-row-list {
        margin-top: 0;
      }

      legend {
        margin-bottom: 5px;
      }

      .list-group.instructions {
        list-style: decimal inside;
        margin-bottom: 0;

        li a span.glyphicon {
          font-size: 12px;
        }

        .list-group-item {
          display: list-item;
          border: none;
          padding: 2px 10px;
        }
      }
    }

    .card.stripe {
      .status-info-row {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .status-info {
          flex: 1;
          background-color: #e5e5e5;
          padding: 10px;
          margin-right: 20px;
          width: 250px;
          border-radius: 4px;

          dl {
            margin-bottom: 0;
          }
        }

        .stripe-button {
          flex: 1;
        }
      }

      i {
        font-size: 14px;

        &.fa-square-o {
          color: $color-red;
        }

        &.fa-check-square-o {
          color: $color-green;
        }
      }
    }

    .confirmation {
      margin-bottom: 45px;

      .help {
        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      .provided-data {
        .list-group-item {
          border: none;
          padding: 12px;
        }

        a {
          padding-left: 5px;
        }

        i {
          font-size: 18px;

          &.fa-square-o:not(.optional) {
            color: $color-red;
          }

          &.fa-check-square-o {
            color: $color-green;
          }
        }
      }

      .confirm-form {
        padding-top: 6px;

        .agreement {
          text-transform: none;
          font-size: 16px;
          color: #333;

          &.has-error {
            color: #a94442;
          }
        }

        .form-footer {
          & > div {
            justify-content: center !important;
          }
        }
      }
    }

    .wizard {
      .wizard-inner {
        width: 100%;
        border-bottom: solid 4px $color-green;
        margin-bottom: 25px;

        span[class^="step-*"], span[class*='step-'] {
          display: none;
        }

        .ok:not(.rejected) .step-ok, .ko:not(.rejected) .step-ko, .na .step-na, .rejected .step-rejected {
          display: block;
          flex: 1;
          padding-right: 4px;
        }

        .step-ok {
          color: $color-green;
        }

        .step-ko, .ok.rejected .step-ok {
          color: $color-orange;
        }

        .step-na {
          color: $color-gray;
        }

        .nav-tabs {
          & > li {
            & > a, & > a:hover {
              display: inline-flex;
              align-items: center;
              font-size: 16px;
              color: $color-gray;
              border-color: transparent;
            }

            &.ok.rejected > a {
              color: $color-orange;
            }

            &.ok:not(.rejected) > a {
              color: $color-green;
            }
          }

          & > li.active > a {
            color: $color-dark;
            font-weight: bold;
            background-color: transparent;
          }
        }
      }
    }

    fieldset {
      margin: 20px 0 2px;

      legend {
        border-bottom-width: 2px;
      }
    }

    .filter-option {
      color: #565656;
    }

    .dropdown-toggle {
      opacity: 1;
      padding: 8px 12px;
    }

    .field-list-item {
      margin: 0;
      padding: 0;
    }
  }
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: pointer;
  display: block;
}


.file-row-list {
  margin-top: 2em;

  .file-row {
    margin: 16px 0;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 5px;
    }

    a.delete-file {
      position: absolute;
      top: 0;
      right: 0;
      float: right;
    }

    .uploaded-file, .uploaded-file-error {
      display: none;

      span.icon {
        padding-left: 0.5em;
        cursor: pointer;
        font-size: smaller;
      }
    }

    .uploaded-file-error {
      color: $color-red;
    }

    &.frozen .uploaded-file {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      .file-controls .delete-file:hover {
        cursor: pointer;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .progress {
    margin-bottom: 0;
  }
}

.tos-status, .payouts-status, .payments-status {
  text-transform: uppercase;
  padding: .2em .6em .2em;
}

.payouts-status, .payments-status {
  background-color: $color-green;

  &.disabled {
    background-color: $color-red;
  }
}

.tos-status {
  background-color: $color-blue;

  &.not-accepted {
    background-color: $color-red;
  }

  &.accepted {
    background-color: $color-green;
  }
}

.profile-status {
  background-color: $color-red;
  text-transform: uppercase;

  &.badge {
    font-size: 1em;
    vertical-align: baseline;
  }

  &.label {
    padding: .2em .6em .2em;
    vertical-align: baseline;
  }

  &.not_started, &.started, &.information_missing {
    background-color: $color-gray;
  }

  &.ready_for_review, &.amended {
    background-color: $color-orange;
  }

  &.under_review {
    background-color: $color-blue;
  }

  &.rejected {
    background-color: $color-red;
  }

  &.approved {
    background-color: $color-green;
  }
}

.kyc-profile-actions {
  margin: 20px 0 10px;

  .force-approve span.glyphicon {
    color: $color-orange;
    font-size: 10px;
  }
}

.internal-note {
  position: relative;
  border: none;
  padding: 0 50px 0 0;
}

.merchant-summary {
  .glyphicon {
    font-size: smaller;
  }
}

.kyc-profile, .merchant-profile-info {
  .glyphicon {
    padding-left: 0.5em;
    cursor: pointer;
    font-size: smaller;
  }

  .notes-controls {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    font-size: 14px;
  }

  .fieldset-group fieldset:last-child {
    margin-bottom: 30px;
  }
}

.kyc-profile {
  dl ul {
    list-style: none;
    padding-left: 0;
  }

  .panel-title {
    a:hover, a:focus {
      color: inherit;
      text-decoration: underline;
    }
  }

  .panel-body legend {
    font-size: 16px;
  }

  .file-row-list {
    margin-top: 0;
  }

  .no-data {
    font-size: inherit;
    margin-bottom: 0;
  }

  .label.tos-acceptance {
    background-color: $color-orange;
    font-size: inherit;

    &.accepted {
      background-color: $color-green;
    }
  }

  .requirement {
    h3 {
      padding-top: 7px;
    }

    .requirement-controls {
      float: right;
      font-size: 1.5em;
      line-height: 1em;
      top: 3px;
      color: $color-gray;

      .btn {
        color: #333;
        border-width: 1px;
      }
    }

    .comment {
      blockquote {
        border-left: none;
        padding: 0 0 10px 0;
        margin-bottom: 0;
      }

      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}

.validatable {
  &.was-validated {
    .form-control:invalid {
      border-color: $color-red;
    }

    .form-control:invalid ~ .invalid-feedback {
      display: block;
    }
  }

  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: $color-red;
  }
}

.review-modal, .comment-modal, .modal-dialog {
  .alert {
    border-radius: 4px;
  }
}

.merchant-details {
  a[aria-controls="change-log-list"] {
    float: right;
  }

  .change-log > div {
    margin-top: 3em;
  }

  span[data-trigger="hover"] {
    cursor: help;
  }

  .since-date {
    text-indent: 32px;
  }

  select[name*="[country]"] + .choice-help-text {
    padding-left: 0;
  }

  .shares-data-with {
    p {
      margin: 0;
    }

    ul {
      padding-left: 10px;

      li {
        list-style-type: none;
      }
    }
  }
}

.row.merchant-contact {
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  margin: 15px 0;
  padding-left: 15px;

  .merchant-contact-label {
    text-decoration: underline;
    font-size: inherit;
    color: inherit;
    margin-left: -10px;
  }

  .merchant-contact-help {
    margin-left: -10px;

    .help-block {
      padding-left: 0;
    }
  }

  .additional-contact {
    border: solid 1px #e5e5e5;
    border-radius: 5px;
    margin: 10px;
    padding: 15px;

    .delete-item {
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
}

.signature-pad {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 10px;
  width: 100%;
  max-width: 700px;
  max-height: 460px;
  border-radius: 4px;
  height: 300px;
  margin: 0 auto;

  .signature-pad--body canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
  }

  .button.clear {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    font-size: 1.7em;
    height: 1.7em;
    margin: -1.7em 0em 0 0;
    opacity: 0.6;
    padding: .3em;
    z-index: 100;
  }

  .signature-pad--body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid #f4f4f4;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.08) inset;
  }
}

.loader {
  padding: 80px;
  text-align: center;
}

.terms-and-conditions {
  padding: 0 2em 1em 3em;

  h3, h4 {
    font-weight: bold;
  }

  h3 {
    margin-bottom: 2em;
  }

  h4 {
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 0;
  }

  p.list {
    padding-left: 2em;
    text-indent: -2em;
  }
}

.accounting2 {
  .popover {
    max-width: 50vw;
    min-width: 420px;
  }

  .label-default {
    cursor: default;
  }
}

.account-tag {
  .btn {
    margin-left: 0 !important;
  }

  .action-unsnooze {
    display: none;
  }

  .action-snooze {
    display: block;
  }

  .context, .title {
    display: none;
  }

  &.snoozed {
    .btn {
      opacity: 0.5;
    }

    .action-unsnooze {
      display: block;
    }

    .action-snooze {
      display: none;
    }
  }
}

.btn-group.action-buttons {
  float: right;

  .btn + .btn {
    margin-left: 5px;
  }
}

.header-nav {

  &.row {
    margin-bottom: 2em;
  }

  .nav-links {
    margin-top: 20px;

    .btn-group {
      float: right;
    }

    .btn {
      border-width: 1px;
      color: #333;
      background-color: #fff;
      border-color: #ccc
    }

    .btn.active {
      color: #333;
      background-color: transparent;
      border-color: #adadad;
    }
  }
}

.table-payment-methods {
  tbody {
    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }
    }
  }

  .checkbox {
    width: auto;
    text-align: left;
    margin: 0;

    label {
      padding-left: 30px;
      line-height: inherit;
    }
  }

  label {
    text-transform: none;
    font-size: 14px;
    padding-left: 0;
    color: #333;
    margin: 0;
  }

  select {
    width: 250px;
  }

  .choice-help-text {
    display: block;
    clear: both;
    padding-left: 0;
  }

  .form-group {
    margin: 0;
  }

  .help-block {
    display: block;
    padding: 8px 0 0 0;
    margin: 0;
    font-size: small;
  }

  .option-checkbox {
    .help-block {
      display: block;
      margin: 4px 0 0 30px;
      padding: 0;
      font-size: small;
      width: auto;
    }
  }
}

.checkbox-help-text, .choice-help-text {
  padding-left: 35px;
  padding-top: 5px;
  display: inline-block;
}

.help-block {
  padding: 5px 0 0 5px;
}

.checkbox {
  label[disabled] {
    color: #9A9A9A !important;
  }

  &.checked {
    label[disabled] {
      .second-icon {
        color: #DDDDDD;
      }
    }
  }
}

.collection-widget-list {
  .add-collection-widget {
    border-color: transparent;
    color: $color-link-blue;
    padding-left: 0;
    margin-left: 0;

    :first-child {
      margin-top: 10px;
    }
  }

  div > .add-collection-widget:first-child {
    margin-top: 10px;
  }
}

.field-list-item {
  margin: 5px 0 10px 0;
  padding-right: 25px;
  position: relative;

  .checkbox input[type="checkbox"] {
    display: none;
  }

  input, .delete-item {
    display: inline-block;
  }

  .delete-item i {
    width: 25px;
    height: 34px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 9px;
    padding: 5px 0 0 5px;
    cursor: pointer;
    text-align: center;
  }
}

.personalization-settings {
  .uploader-group {
    position: relative;

    .progress {
      position: absolute;
      margin: 0;
      height: 2px;
      left: 0;
      right: 0;
      bottom: -10px;
    }
  }

  .stamp-logo-preview-container {
    max-width: 200px;
    margin: 0 auto;

    .stamp-logo-preview {
      position: relative;
      width: 100%;
      padding-top: 33.3333%;

      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.account {
  .account-preferences {
    form button[type=submit] {
      float: right;
    }
  }

  ol.two-fa {
    margin-left: -40px;

    form {
      margin-top: 15px;
    }
  }
}

.daterangepicker {
  .daterange-header {
    padding: 5px 10px;
    font-size: 9pt;
    text-align: right;
  }

  &.show-ranges:not(.show-calendar) {
    .daterange-header {
      display: none;
    }
  }
}

.table-dashboard-documents {
  .date, .link {
    white-space: nowrap;
    width: 1px;
  }
}

.message {
  padding: 0 10px;

  .message-body {
    margin: 40px auto;
    width: 40%;
    min-width: 600px;
  }

  .attachment-list {
    margin: 20px auto;

    .attachment .glyphicon {
      font-size: x-small;
    }
  }
}

form.merchant-profile-search-filter button[type=submit] {
  margin-top: 25px;
}

.address-widget {
  .row {
    &:last-of-type {
      .form-group {
        margin-bottom: 0;
      }
    }

    & > div[class*=col] {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.business-owners-list {
  .business-owner-title {
    font-weight: bold;
  }

  .field-list-item {
    margin-bottom: 32px;
  }

  .file-row-list {
    margin-top: 0;
  }

  .delete-item {
    top: 0 !important;
    right: 0 !important;
    padding: 0;
    position: initial;
    font-size: 20px;
    height: 23px !important;
  }
}

.login-form, .forget-form {
  .control-label {
    line-height: 40px;
  }
}

.dashboard .accounts {
  .tab-content {
    margin: 5px 0 5px 2px;
  }

  .footer div {
    display: block;
  }

  .account {
    dl {
      padding-top: 5px;
      margin-bottom: 5px;
    }

    dl.total {
      border-top: solid 1px #ddd;
      font-size: 16px;
      margin-bottom: 0;
    }

    dt {
      width: 240px;
      text-align: left;
      font-weight: normal;
    }

    dt, dd {
      line-height: 2;
    }
  }
}

.accounts .accounts-filter, .users .users-filter {
  button[type=submit] {
    margin-top: 15px+12px;
  }
}

.merchants-risks-switchers {
  .checkbox {
    text-align: right;

    label {
      padding-right: 28px;
    }

    .icons {
      right: 0;
      left: auto;
      top: 1px;
    }
  }
}

.card-user {
  .activation-switcher {
    .btn-group {
      width: 100% !important;
    }
  }

  .author {
    margin-bottom: 20px;
  }

  .dl-horizontal {
    dt, dd {
      width: 50%;
    }

    dt {
      padding-right: 5px;
    }

    dd {
      padding-left: 5px;
    }
  }
}

.verification-icon {
  font-family: 'Pe-icon-7-stroke';
  padding-right: 4px;
  vertical-align: -0.09em;
  font-weight: bold;
  font-style: normal;
  font-size: 12pt;

  &.rejected:before {
    content: "\e681";
  }

  &.verification_not_required:before {
    content: "\e64a";
  }

  &.verification_pending:before {
    content: "\e6c2";
  }

  &.verified:before {
    content: "\e66c";
  }
}

.status-bar {
  float: right;
  padding: 18px 15px 0 0;

  .label {
    text-transform: uppercase;
    padding: 0.2em 0.4em;
    margin-right: 0.2em;
    font-weight: normal;
    font-size: 9pt;
  }
}

.legal-setup-main-merchant {
  text-transform: uppercase;
  font-weight: bold;

  &.trekksoft {
    background-color: $color-trekksoft;
  }
  &.payyo {
    background-color: $color-payyo;
  }
  &.stripe {
    background-color: $color-stripe;
  }
  &.amnis {
    background-color: $color-amnis;
  }
}
.legal-setup-payment-gateway {
  text-transform: uppercase;
  font-weight: bold;

  &.payyo {
    background-color: $color-payyo;
  }
  &.datatrans {
    background-color: $color-datatrans;
  }
}

.verification-status {
  text-transform: uppercase;
  font-weight: bold;

  &.rejected {
    background-color: $color-red;
  }

  &.verification_not_required {
    background-color: $color-gray;
  }

  &.verification_pending {
    background-color: $color-orange;
  }

  &.verified {
    background-color: $color-green;
  }
}

.merchant-status-board {
  font-size: 16px;

  .verification-status {
    text-transform: uppercase;
    font-size: 75%;
    font-weight: bold;
  }

  .link-icon {
    font-size: 10pt;
    color: #9A9A9A;
    margin-left: 3px;

    &:hover {
      text-decoration: none;
    }
  }

  dl {
    margin-bottom: 0;

    dt, dd {
      font-weight: normal;
      line-height: 1.7;
    }

    dt {
      text-align: left;
    }
  }

  .switch-icon {
    padding-right: 3px;
    top: 3px;
    font-size: 110%;

    &.on {
      color: $color-green;
    }

    &.off {
      color: $color-red;
    }
  }

  .id {
    font-family: monospace;
    overflow-wrap: break-word;
    font-size: 90%;
  }

  .panel-footer {
    padding: 5px 15px;

    div[class^="col"] {
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      float: none;
      padding: 3px;

      &:not(:first-child) {
        border-left: solid 1px #ddd;
      }
    }
  }
}

ul {
  &.payment-methods {
    margin-bottom: 20px;
    padding: 0 0 0 16px;

    li {
      padding: 10px 0;
      list-style-position: outside;
    }

    .help-block {
      padding: 5px 0 0 0;
      display: block;
    }
  }
}

.disputes {
  .link-icon {
    font-size: 9pt;
    color: #9a9a9a;
    margin-left: 3px;

    &:hover {
      text-decoration: none;
    }
  }
}

.integrator-reports {
  .download-link {
    a i, i {
      font-size: 150%;
      font-weight: bold;
    }
  }
}

.table-bank-accounts {
  tbody td em {
    color: #aaa;
    font-weight: bold;
    font-style: normal;
  }

  tfoot {
    td {
      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }
  }
}

.validation-status {
  display: flex;
  align-items: center;
  margin: 3px 0;
  line-height: 24px;

  i {
    font-size: 24px;
    font-weight: 900;
    margin-right: 2px;
  }

  span {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.2;
  }

  &.valid {
    color: $color-green;
  }

  &.formally-valid {
    color: $color-yellow;
  }

  &.invalid {
    color: $color-red;
  }
}

.merchant-bank-accounts-form {
  .help-block, .choice-help-block {
    display: block;
    background-color: #ccc;
    color: #444;
    padding: 10px 15px;
    font-size: 80%;
    border-radius: 4px;
    margin-top: 10px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.req-attachment {
  .description-form {
    margin-top: 15px;
  }

  .simple-file {
    padding-right: 0.5em;
    display: inline-block;

    i {
      vertical-align: middle;
      padding-left: 2px;
    }

    .form-group {
      display: inline-block;
      margin-bottom: 0;
    }

    .delete {
      cursor: pointer;
    }
  }

  .btn-link {
    border: none;
  }
}

.req-attachment-description {
  font-size: 12pt;
  border-left: none;
}

.topnav {
  [class^="status-"] {
    font-size: 14px;
    padding: 6px 15px;
    color: #fff;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .status-normal {
    background-color: $color-blue;
  }

  .status-alert {
    background-color: $color-red;
    .status {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.page-status {
  padding-left: 10px;
  margin: 20px 0;
  display: inline-block;
}

.container-integration-status {
  h3 {
    text-align: center;
    margin-bottom: 30px;
  }

  .row.test-result {
    margin: 10px 0;

    .status-container {
      font-size: 30px;
      padding-top: 17px;
      text-align: right;
    }

    .card {
      margin-bottom: 0;
      padding-bottom: 10px;

      .header {
        cursor: pointer;
      }
    }

    .bg-success, .bg-warning, .bg-danger {
      padding: 3px 8px;
      margin: 2px 0;
    }
  }
}

.correspondence-language {
  margin: 10px;
  color: $color-dark;
  border: $color-gray 1px solid;
}

.business-structure-widget {
  .radio {
    cursor: pointer;
    margin: 0;

    .icons {
      font-size: 21px;
      margin-top: 4px;
    }
  }

  .list-group-item {
    border: none;
    padding: 15px;
    p {
      padding-left: 32px;
      font-size: 15px;
    }
  }

  label {
    color: #333;
    margin: 0;
    padding: 0;
    font-size: 20px;
    text-transform: none;

    &.required {
      &:after {
        content: none;
      }
    }
  }
}

.confirmation-form {
  .checkbox {
    margin-bottom: 8px;

    & + .help-block {
      padding: 0;
      margin-top: 0;
      margin-left: 30px;
    }
  }
}

.text-top {
  vertical-align: top !important;
}

.form-property-information {
  .form-group > span.icons {
    display: none;
  }
  .form-group {
    .radio {
      label {
        position: relative;
        padding-left: 25px;
      }
    }
  }
  legend {
    position: relative;
    .note {
      position: absolute;
      font-size: 13px;
      bottom: -24px;
    }
  }
}

.property-information {
  .group-help-text {
    margin: 5px 0 0;
    display: block;
  }
}

.btn-inline {
  margin-top: 23px;
  margin-right: 6px;
}

.btn-xxs {
  font-size: 10px !important;
  border-radius: 2px;
  padding: 1px 2px;
}

.table.table-select {
  border-collapse: separate;

  > thead > tr > th {
    border-bottom: none;
  }

  > tbody {
    tr.selected {
      position: inherit;

      > td:first-child {
        border-left: solid 1px $color-blue;
      }

      > td:last-child {
        border-right: solid 1px $color-blue;
      }

      > td {
        background-color: rgba($color-blue, 0.1);
        border-top: solid 1px $color-blue;
        border-bottom: solid 1px $color-blue;
      }
    }
  }
}

.terms-of-service {
  header {
    height: 6rem;
    display: flex;
    border-bottom: 1px solid #e4edf9;
    padding: .5rem;
    background-color: #f5f5f5;

    img {
      max-height: 100%;
      max-width: 100%;
      padding-left: 1.5rem;
    }
  }

  h1 {
    color: #000;
  }

  hr {
    width: 6rem;
    border: solid 1px #000;
  }

  .tos-text {
    margin: 4rem 0;

    .content {
      padding: 40px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }
  }

  footer {
    height: 6rem;
    display: flex;
    color: #777777;
    justify-content: center;
    align-content: center;
  }
}

table.processing-fees {
  .input-group input:nth-child(2) {
    border-left: none 0;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  .form-control {
    font-size: 10pt;
    padding: 3px 8px;
    height: 36px;
  }

  .inline-help-block {
    display: table-cell;
    padding: 6px 12px;
    font-weight: 400;
    line-height: 1;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    color: #9a9a9a;
    min-width: 68pt;
    font-size: 10pt;
  }

  .input-group-addon {
    padding: 3px 8px;
    font-size: 10pt;

    select {
      height: 27px;
      padding: 0;
      margin: 0;
    }
  }

  .processing-fee-remove {
    font-size: 8pt;
  }

  .fi-subtype-block {
    .fi-subtype-dropdown {
      width: 100%;
      display: block;
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }
    .fi-subtype-tooltip {
      display: none;
      i {
        font-size: 16px;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
    &.with-info {
      .fi-subtype-dropdown {
        width: 91%;
        display: inline;
      }
      .fi-subtype-tooltip {
        display: inline;
      }
    }
  }
}

.integrator-config {
  .header {
    padding-left: 0;
  }

  .table {
    tbody td:first-child, thead th:first-child {
      padding-left: 12px;
    }
  }
}

.responsive-tabs.nav-tabs {
  position: relative;
  z-index: 10;
  overflow: visible;
  border-bottom: none;

  @media(min-width: $screen-sm-min) {
    border-bottom: 1px solid #ddd;
  }

  span.responsive-tabs-arrow {
    position: absolute;
    top: 14px;
    right: 22px;
    cursor: pointer;

    &.glyphicon-triangle-top {
      display: none;
    }
    @media(min-width: $screen-sm-min) {
      display: none;
    }
  }

  > li {
    display: none;
    float: none;
    text-align: center;

    &:last-of-type > a {
      margin-right: 0;
    }

    > a {
      margin-right: 0;
      background: #fff;
      border: 1px solid #dddd;

      @media(min-width: $screen-sm-min) {
        margin-right: 4px;
      }
    }

    &.active {
      display: block;

      a {
        @media(min-width: $screen-sm-min) {
          border-bottom-color: transparent;
        }
        border: 1px solid #ddd;
        border-radius: 2px;
      }
    }

    @media(min-width: $screen-sm-min) {
      display: block;
      float: left;
    }
  }

  &.open {
    span.responsive-tabs-arrow {
      &.glyphicon-triangle-top {
        display: block;
        @media(min-width: $screen-sm-min) {
          display: none;
        }
      }
      &.glyphicon-triangle-bottom {
        display: none;
      }
    }

    > li {
      display: block;

      a {
        border-radius: 0;
      }

      &:first-of-type a {
        border-radius: 2px 2px 0 0;
      }
      &:last-of-type a {
        border-radius: 0 0 2px 2px;
      }
    }
  }
}

.processing-progress {
  margin-top: 10px;
  height: 110px;
  overflow-y: scroll;
}

form[name="processing_fees"] {
  .form-group .help-block {
    margin-left: 15px;
  }
}

.payment-agreement-status {
  text-transform: uppercase;

  &.badge {
    font-size: 1em;
    vertical-align: baseline;
  }

  &.active {
    background-color: $color-green;
  }

  &.failed {
    background-color: $color-red;
  }

  &.halted {
    background-color: $color-gray;
  }

  &.complete {
    background-color: $color-blue;
  }
}

.invoice-status {
  text-transform: uppercase;

  &.outstanding {
    background-color: $color-yellow;
  }

  &.paid {
    background-color: $color-green;
  }

  &.failed {
    background-color: $color-red;
  }

  &.halted {
    background-color: $color-gray;
  }
}

.table-logs {
  table-layout: fixed;

  td {
    vertical-align: top !important;
  }

  .col-toggle {
    width: 32px;

    i {
      cursor: pointer;
    }
  }

  .col-occurred-on {
    white-space: nowrap;
  }

  .col-message {
    overflow: hidden;
    text-overflow: ellipsis;

    .info {
      opacity: 90%;
      padding: 0 15px;
      white-space: nowrap;
    }

    .details {
      .auth-data-loader {
        margin: 15px 0 0;
      }

      pre {
        font-size: smaller;
        margin: 15px 0 0;
      }

      dl {
        margin: 15px 0 0;

        dt {
          width: 120px;
          text-align: left;
          font-weight: normal;
        }

        dd {
          margin-left: 135px;
        }
      }
    }
  }
}

.user-accounts {
  .delete-item {
    top: 30px
  }
}

.form-widget.number-range-widget {
  .row > div[class*="col-"] {
    padding-top: 0;
  }
}

.payouts-overview {
  table.payouts {
    .payouts-actions {
      .accounting-process-error {
        color: $color-orange;
        font-size: 150%;
        cursor: pointer;

        &.re-sent {
          color: inherit;
          cursor: inherit;
        }
      }
    }
  }
}

.table-payout-documents-details {
  margin-bottom: 0;

  td {
    padding: 3px 6px !important;
  }
}

.documents-details {
  .pe-7s-info {
    font-size: 150%;
  }
}

.list-group.list-group-tree {
  padding: 0;

  .list-group {
    padding-left: 20px;
  }

  i.pe-7s-angle-right-circle {
    margin-right: 5px;
  }
}

.date-of-birth {
  .btn {
    width: auto;
    padding-right: 30px !important;
  }

  .form-control:not(:last-child) {
    .btn {
      border-right-width: 0 !important;
    }
  }

  .btn-group {
    width: auto !important;
  }

  .date-group {
    display: block;
  }
}

.font-weight-normal {
  font-weight: normal !important;
}

.breakdown-table {
  thead tr th span {
    text-transform: none;
    display: block;
    color: #666;
  }

  thead:not(:first-child) tr th {
    padding-top: 24px;
    font-size: 18px;
  }

  i.info {
    padding-left: 5px;
  }

  td[rowspan] {
    vertical-align: top;
  }

  th > span {
    font-weight: normal;
  }

  th > div {
    font-weight: normal;
    font-size: 1.3rem;
  }

  .text-end {
    text-align: right;
    white-space: nowrap;
  }

  td.extra-ident {
    padding-left: 30px;
  }

  tr.is-info {
    opacity: .5;
  }

  tbody.is-collapsed tr:not(:first-child) {
    display: none;
  }

  tbody.is-collapsed tr:first-child .text-end {
    font-weight: normal;
    font-size: 1.6rem;
  }

  tbody:not(.is-collapsed) tr:first-child .text-end {
    visibility: hidden;
  }

  i.section-collapser,
  tbody.collapsible tr:first-child {
    cursor: pointer;
  }

  tbody.collapsible:hover {
    th:first-child {
      background-color: #eee;
    }
    td:first-child {
      background-color: #f5f5f5;
    }
  }

  tbody tr td:nth-child(3) {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  tr.subtotal td:not(:nth-child(3)) {
    text-decoration: underline double;
  }
}

.payout-action-dropdown {
  .dropdown-toggle {
    cursor: pointer;
  }

  .pe-7s-angle-down-circle {
    font-size: 150%;
  }
}

.table-profile-logs {
  td {
    vertical-align: top !important;
  }
  pre {
    padding: 0;
    background-color: transparent;
    border: 0;
    margin: 3px 0 0 0;
    font-size: smaller;
  }
}

body.dispute-information {
  padding-top: 20px;
  padding-bottom: 55pt;

  ul.support-evidence-list {
    padding-left: 0 !important;
    margin-bottom: 0;

    .add-support-evidence {
      margin-right: .3em;
    }

    .delete-support-evidence i {
      @extend .fa-solid;
      @extend .fa-xmark;
      font-size: 14pt;
      color: $color-gray;
    }

    li {
      border-right-style: none;
      border-left-style: none;
      border-radius: 0;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .mime-type {
    font-size: 18pt;
    color: $color-gray;
  }

  .form-group {
    label {
      color: rgba($color-dark, 0.6);
    }

    .checkbox label.required::after {
      content: "";
    }
  }

  h4.title .label {
    margin-bottom: 0;
  }

  .header {
    padding-bottom: 15px;
    border-bottom: 1px solid #e5e5e5;

    &.nav {
      margin-bottom: 30px;

      .title {
        margin-left: -40pt;
      }
    }
  }

  .card {
    .header {
      h4, h5 {
        margin: inherit;
      }
    }
    .content {
      ul {
        padding-left: 1.5em;
      }
    }
    .footer {
      padding: 15px;
      .row {
        display: block;
      }

      button {
        margin-left: 10px;
      }
    }
  }

  .payment-details {
    h5 {
      margin-bottom: 1.5em;
    }

    ol.resolve-steps {
      padding-left: 20px;

      li {
        margin-bottom: 12px;
      }
    }

    dl {
      &.dl-horizontal {
        dt {
          width: 100px;
          text-align: left;
          font-weight: normal;
          margin-bottom: 12px;
        }

        dd {
          margin-left: 120px;
          margin-bottom: 0;
        }
      }

      dd {
        margin-bottom: 15px;
      }

      dt {
        color: rgba($color-dark, 0.6);
        margin-bottom: 6px;
        font-weight: normal;
      }
    }
  }


  > .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50pt;
  }
}

.toggle-payout-settings {
  .btn.btn-link {
    border-color: transparent;
    width: 100%;
    height: 100%;
    text-align: left;
  }
}

form.payyo-pos-config {
  .dl-horizontal dt {
    text-align: left;
  }

  .table tbody {
    & td:first-child {
      padding-left: 0;
    }

    & td:last-child {
      padding-right: 0;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
