
#number_container {
    width         : 200px;
    height        : 40px;
    margin-bottom : 4px;
}

#cvc_container {
    width         : 60px;
    height        : 40px;
    margin-bottom : 4px;
}

#expiry_month {
    display : inline-block;
    width   : 140px;
}

#expiry_year {
    display : inline-block;
    width   : 80px;
}

#currency, #amount {
    display      : inline-block;
    width        : 80px;
    margin-right : 10px;
}

code.transaction-id {
    padding-right : 10px;
}

.help-block {
    padding : 0 !important;
}

.help-block p {
    font-size   : 14px;
    line-height : 16px;
}
