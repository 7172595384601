@import "mixins";

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

$screen-xs-max: 767px;

$screen-sm-min: 768px;
$screen-sm-max: 991px;

$screen-md-min: 992px;
$screen-md-max: 1199px;

$screen-lg-min: 1200px;

// 600 on https://www.materialui.co/colors
$color-white : rgb(256, 265, 256);
$color-green : #43A047;
$color-blue : #039BE5;
$color-orange : #FB8C00;
$color-yellow : #FDD835;
$color-red : #E53935;

$color-payyo: #43394d;
$color-payyo-alt: #42e1b8;
$color-trekksoft: #02cc85;
$color-amnis: #3333ff;
$color-stripe: #635bff;
$color-datatrans: #213c61;

$color-dark : #2e2d2d;
$color-gray : rgba($color-dark, 0.5);
$color-light-gray: rgba($color-dark, 0.05);

$color-link-blue : #1DC7EA;

@-moz-document url-prefix() {
  select.form-control {
    padding-right: 25px !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 7px) 50%;
    -moz-appearance: none;
    appearance: none;
  }
}

.sidebar {
  border-right : 1px solid rgba(0, 0, 0, 0.1);
  box-sizing   : content-box;
}

.theme-background {
  background-color: $color-payyo !important;
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

.font-color-base {
  color : $color-gray;
}

.font-color-yes, .font-color-success {
  color : $color-green;
}

.font-color-no {
  color : $color-red;
}

.font-color-orange {
  color: $color-orange;
}

.glyphicon.glyphicon-dot:before {
  content   : "\25cf";
  font-size : 1.5em;
}

.wrapper.in-wizard-mode {
  .sidebar, .topnav {
    display: none;
  }
  .main-panel {
    float: none;
    width: 100%;
    & > .content {
      @media (min-width: 768px) {
        margin: 0 10%;
      }
      @media (min-width: 992px) {
        margin: 0 20%;
      }
    }
  }
}
