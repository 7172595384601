
.vue-processing-fees {
    select {
        width: 140px;
    }

    input[type="text"], input[type="number"] {
        width: 100px;
    }

    select.currency {
        width: 50px;
        margin-right: 5px;
    }

    button.add-row {
        margin-left: 10px;
        font-size: small;

        svg {
            padding-top: 4px;
        }
    }

    a.fi-subtype-tooltip {
        margin-left: 3px;

        i[class^="pe-7s-"] {
            font-size: 12pt;
            vertical-align: text-bottom;
        }
    }
}
