
.tos-bar-container {
    margin: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 10rem;
    z-index: 100;
    border-top: solid 3px #ffa500;
}

.tos-bar .close-btn a {
    font-size: 5rem;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
}

.tos-bar-container.fade-enter-active, .tos-bar-container.fade-leave-active {
    transition: opacity 1s
}

.tos-bar-container.fade-enter-from, .tos-bar-container.fade-leave-to {
    opacity: 0;
}

.tos-bar-container .tos-bar {
    padding: 0 50px;
    background: #2d2929;
    color: #fff;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tos-bar-container .tos-bar p {
    text-align: center;
    margin: 0;
}

.tos-bar-container .tos-bar p a {
    white-space: nowrap;
}

